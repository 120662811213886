import React, { useEffect, useRef } from 'react';
import { Engine, Scene, ShadowGenerator, ArcRotateCamera, FreeCamera, Vector3, HemisphericLight, DirectionalLight, PointLight, Color4 } from '@babylonjs/core';
import * as BABYLON from '@babylonjs/core';
import * as GUI from '@babylonjs/gui';


import '@babylonjs/loaders'; // Required for GLTF and GLB formats
import { SceneLoader, SpotLight } from '@babylonjs/core';

const SceneComponent = ({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady }) => {
  const canvas = useRef(null);

  useEffect(() => {
    if (!canvas.current) return;
  
    const engine = new Engine(canvas.current, antialias, engineOptions, adaptToDeviceRatio);
    const scene = new Scene(engine, sceneOptions);

    scene.clearColor = new BABYLON.Color3(1,1,1)
    var camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 0.3, -0.8), scene);
    camera.speed = 0.01;
    camera.minZ = 0.001;
    scene.activeCameras.push(camera);
    camera.attachControl(canvas, true);

    camera.inputs.clear();

    
    //camera.inputs.attached.mouse.angularSensibility = 0;

    var light = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 0.3, 8), scene);
    var light2 = new BABYLON.HemisphericLight("light2", new BABYLON.Vector3(-2, 0.3, 0), scene);
    var light3 = new BABYLON.HemisphericLight("light3", new BABYLON.Vector3(2, 0.3, 0), scene);

    light.intensity = 0.25;
    light2.intensity = 0.8;
    light3.intensity = 0.8;
    var pbr = new BABYLON.PBRMetallicRoughnessMaterial("pbr", scene);

    //console.log(pbr.environmentTexture);
    var gridSize = 5;
    var box1 = [];
    var box2 = []; 
    //Generate Cubes
    for(var i=0;i<gridSize;i++){
        for(var j=0;j<7;j++){
            var sphereMat = pbr.clone();
            sphereMat.metallic = 0.0;
            sphereMat.roughness = (i/gridSize)/3;
            sphereMat.baseColor = BABYLON.Color3.White();
            var sphere = BABYLON.Mesh.CreateBox("box", 0.35, scene);
            sphere.material = sphereMat;
            sphere.position.y = (i-1)*0.4*1.5;
            sphere.position.x = 0.4*4;
            sphere.position.z = j*0.4*2;

            var cubeMat = pbr.clone();
            cubeMat.metallic = 0.0;
            cubeMat.roughness = (i/gridSize)/3;
            cubeMat.baseColor = BABYLON.Color3.White();
            var box = BABYLON.Mesh.CreateBox("box", 0.3, scene);
            box.material = cubeMat;
            box.position.y = (i-1)*0.4*1.5;
            box.position.x = -0.4*4;
            box.position.z = j*0.4*2;

            box1.push(box);
        }
    }


    // Create default pipeline and enable dof with Medium blur level
    var pipeline = new BABYLON.DefaultRenderingPipeline("default", true, scene, [scene.activeCamera]);
    pipeline.depthOfFieldBlurLevel = BABYLON.DepthOfFieldEffectBlurLevel.Medium;
    pipeline.depthOfFieldEnabled = true;
    pipeline.depthOfField.focalLength = 180;
    pipeline.depthOfField.fStop = 3;
    pipeline.depthOfField.focusDistance = 2250;
    var moveFocusDistance = true;


    scene.activeCameras = [scene.activeCamera];
    const factor_camera = 10000;
    
    
    engine.runRenderLoop(() => {
      if (typeof onRender === "function") onRender(scene);
      pipeline.depthOfField.focusDistance = 600 + (4000 * (Math.sin((new Date).getTime()/1000)+1)/2);
      
      //console.log(scene.pointerX);
      //camera.rotation.y = (scene.pointerX - (window.innerWidth/2))/factor_camera ;
      camera.position.x = (scene.pointerX - (window.innerWidth/2))/factor_camera ;
      console.log(`posX: ${scene.pointerX}`);
      camera.rotation.x = (scene.pointerY - (window.innerHeight/2))/factor_camera ;
      camera.position.Y = (scene.pointerY - (window.innerWidth/2))/factor_camera ;
      console.log(`posX: ${scene.pointerY}`);

      scene.render();
    
    });

    const resize = () => {
      engine.resize();
    };

    window.addEventListener('resize', resize);

    return () => {
      engine.dispose();

      window.removeEventListener('resize', resize);
    };
  }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

  return <canvas ref={canvas} style={{ width: '100vw', height: '100vh' }} />;
};

export default SceneComponent;
