import { FreeCamera, Vector3, HemisphericLight, MeshBuilder } from "@babylonjs/core";
import SceneComponent from "./components/SceneComponent"; 
import { SceneLoader } from "@babylonjs/core";
import "@babylonjs/loaders";
import logo from './logo.svg';
import './App.css';
import {useEffect, useRef} from 'react';
import anime from 'animejs';




function App() {
  const card_container = useRef(null);
  const about = useRef(null);

  function open_description(){
    about.current.style.display = "block";
  }
  function close_description(){
    about.current.style.display = "none";
  }

  useEffect(() => {
    anime.timeline()
    .add({
      targets: '.wrapper',
      easing: 'easeOutExpo',
      filter:[
        {value: 'blur(10px)'},
        {value: 'blur(0px)'}
      ],
      duration: 3000
    })


  }, [])
  return (
    <div ref={card_container} className="wrapper">
   
        <div className="card center" style={{zIndex: 3}}>
          <img src="../logo.svg"/>
          <h1>NetworkNomad</h1>
          <h4>Unparalleled Staking</h4>
          <div onClick={open_description} style={{marginTop: '0.5rem'}} className="nav-item"> About </div>
          <a href="https://twitter.com/_networknomad" target="_blank" className="nav-item"> Twitter </a>
          <a href="https://matrix.to/#/@networknomad:matrix.org" target="_blank" className="nav-item"> Contact </a>
          <div style={{marginTop: '0.5rem'}} className="footer">[ coming to you soon ]</div>
        </div>

        <div ref={about} className="card center" style={{zIndex: 4, display: 'none'}}>
          <div className="bar"></div>
          <div className="flex col">
            <h2>About</h2>
            <p> We are a team committed to pushing the boundaries of what's possible in the DeFi space. Our team of experts continuously scouts for innovative staking opportunities and blockchain advancements to ensure our users have access to the best possible options</p>
            <div onClick={close_description} style={{marginTop: '0.5rem'}} className="nav-item"> CLOSE </div>
          </div>
          
        </div>

      <div className="flex center col" style={{position: 'absolute'}}>
        
      </div>
      <div style={{zIndex: '2'}} className="container yellow mix"></div>
      <SceneComponent antialias id="my-canvas" />
    </div>
  );
}

export default App;
